import webFontLoader from 'webfontloader';

webFontLoader.load({
  google: {
    families: [
      'Khand:600,700',
      'Open Sans:400,600,700',
    ],
  },
  custom: {
    families: ['Material-Design-Iconic-Font'],
    testStrings: {
      'Material-Design-Iconic-Font': '\f175',
    },
    urls: [
      'https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css',
    ],
  },
  timeout: 15000,
});
